.shapeSelect {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: calc(100% - 136px);
  margin-top: 36px;
  padding-bottom: 15px;
  width: 100%;
  max-width: 100%;
  background-color: #e9e9e9;
  z-index: 2;
}

.shapeContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 80%;
  transition: transform .4s;
  align-items: center;
}

.shapeContainerTransform {
  transform: translateY(9.3%);
}

.shapeRow {
  display: flex;
  flex-direction: row;
  height: 33%;
  max-width: 600px;
  position: relative;
}

.shape {
  display: flex;
  flex: 1;
  height: 100%;
  position: relative;
  justify-content: center;
}

.shape svg {
  cursor: pointer;
  display: flex;
  position: relative;
}

.svgWallClass {
  transition: transform .4s;
}

.svgPadClass {
  transition: opacity .4s, transform .4s;
}

.hideWall {
  transform: translateY(28%);
}

.hidePad {
  opacity: .2;
  transform: translateY(-28%);
}

.notClickable {
  pointer-events: none;
}

.wallPadSwitch {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 20%;
  justify-content: space-evenly;
}

.shapeSwitch {
  position: relative;
  display: flex;
  width: 300px;
  max-width: 80%;
  height: 46px;
  max-height: 50%;
}

.touchIcon {
  height: 26px;
}

.switchTitle {
  display: flex;
  flex-direction: column;
  cursor: default;
  user-select: none;
  text-align: center;
}

.shapeSwitchText {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  place-content: center;
  user-select: none;
}

.shapeSwitchText > img {
  margin-right: 8px;
  height: 24px;
  max-height: 100%;
}

.shapeSwitch input {
  display:none;
}

.slider {
  position: absolute;
  display: flex;
  cursor: pointer;
  height: 100%;
  width: 100%;
  background-color: #d8d8d8;
  -webkit-transition: transform .4s;
  transition: transform .4s;
  border-radius: 6px;
}

.slider:before {
  position: absolute;
  display: flex;
  content: "";
  height: calc(100% - 2px);
  width: 50%;
  left: -1px;
  -webkit-transition: transform .4s;
  transition: transform .4s;
  border: 1px solid #fff;
  border-radius: 6px;
  -webkit-box-shadow: 0px 2px 7px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 7px -3px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 7px -3px rgba(0,0,0,0.75);background: rgba(252,252,252,1);
  background: -moz-linear-gradient(top, rgba(252,252,252,1) 0%, rgba(235,235,235,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(252,252,252,1)), color-stop(100%, rgba(235,235,235,1)));
  background: -webkit-linear-gradient(top, rgba(252,252,252,1) 0%, rgba(235,235,235,1) 100%);
  background: -o-linear-gradient(top, rgba(252,252,252,1) 0%, rgba(235,235,235,1) 100%);
  background: -ms-linear-gradient(top, rgba(252,252,252,1) 0%, rgba(235,235,235,1) 100%);
  background: linear-gradient(to bottom, rgba(252,252,252,1) 0%, rgba(235,235,235,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfcfc', endColorstr='#ebebeb', GradientType=0 );
}

input:checked + .slider:before {
  -webkit-transform: translateX(calc(100% - 2px));
  -ms-transform: translateX(calc(100% - 2px));
  transform: translateX(calc(100% - 2px));
}
